<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12" sm="4" md="3" lg="3">
        <v-text-field class="d-input-no-foot"
          background-color="white"
          dense
          type="date"
          v-model="start"
          label="Từ"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="3" lg="3">
        <v-text-field class="d-input-no-foot" background-color="white" dense v-model="end" type="date" label="Đến" outlined></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="5" lg="3">
        <v-select class="d-input-no-foot"
          background-color="white"
          dense
          item-text="store_name"
          item-value="id"
          :items="stores"
          v-model="selectedStoreId"
          label="Cửa hàng"
          outlined
        >
          <v-btn
            @click="fetchData"
            :loading="isLoading"
            color="primary"
            slot="append-outer"
            style="margin-top: -6px;"
          >
            <v-icon small>fas fa-sync-alt</v-icon>
          </v-btn>
        </v-select>
      </v-col>
    </v-row>
<v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Tìm tên nhân viên"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  </v-card>
    <v-data-table :search="search" :loading="isLoading" :headers="headers" :items="tableData" class="elevation-1">
        <template v-slot:item.w_checkin="{ item }">
      {{item.checkin_time|toDMYHM}} - {{item.check_in_store_name}}
    </template>
    <template v-slot:item.w_checkout="{ item }">
      <span :style="{
        color:(item.checkout_store_id>0?'':'orange')
      }">{{item.checkout_time|toDMYHM}} - {{item.check_out_store_name}}</span>
    </template>
     <template v-slot:item.w_time_em="{ item }">
        
      <v-chip v-if="!item.checkout_time" color="amber" dark>{{ item.w_hours_diff }}</v-chip>
       
      <v-chip v-else color="#0091EA" dark>{{ item.w_hours_diff }}</v-chip>
    </template>
    <template v-slot:item.w_time_ad="{ item }">
        
 
       <v-chip v-if="item.w_hours_ad_set!==null" color="#4CAF50" dark>{{ item.w_hours_ad_set }}</v-chip>
      <v-chip v-else color="#0091EA" dark>{{ item.w_hours_diff }}</v-chip>
    </template>
    <template v-slot:item.w_ac="{ item }">
      <v-btn-toggle dense color="#033">
        <router-link target="_blank" :to="'/workshifts/'+item.id">
          <v-btn depressed small color="blue darken-1" text-color="white">
            <i class="fas fa-ellipsis-h" style="color:white"></i>
          </v-btn>
        </router-link>
      </v-btn-toggle>
    </template>
    </v-data-table>
  </div>
</template>
<script>
import { getStoreIManage } from "@/api/stores";
import { getWorkShiftsByStore } from "@/api/workshifts";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  created() {
    this.start = Date.today().toString("yyyy-MM-dd");
    this.end = Date.today().toString("yyyy-MM-dd");
    this.isLoading = true;
     this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Chấm công", route: "/timekeeping/employee" },
      { title: "Quản lý chấm công", route: "/timekeeping/employee" }
    ]);
    getStoreIManage().then(res => {
      this.stores = res.data;
      this.selectedStoreId = this.stores[0].id;
      this.isLoading = false;
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      getWorkShiftsByStore({
        store_id: this.selectedStoreId,
        start: this.start,
        end: this.end
      }).then(res => {
        this.tableData = res.data;
        this.isLoading = false;
      });
    }
  },
  data() {
    return {
      start: null,
      end: null,
      search:'',
      stores: [],
      selectedStoreId: null,
      tableData: [],
      isLoading: false,
      headers: [
        { text: "Tên", value: "user_fullname" },
        { text: "Checkin", value: "w_checkin" },
        { text: "Checkout", value: "w_checkout" },
        { text: "Giờ làm", value: "w_time_em", align: "center" },
        { text: "Thực tế", value: "w_time_ad", align: "center" },
        { text: "Actions", value: "w_ac" }
      ]
    };
  }
};
</script>
<style lang="css" scoped>
.d-input-date {
  width: 200px;
  display: inline-block;
  margin-right: 20px;
}
</style>
